import {
  SectionName,
  SectionText,
  SectionTitle,
} from '../../common/commonStyled';
import { NavigationSections } from '../../constants';
import { OfferElements } from './OfferElements';
import { OfferWrapper } from './styled';

const Offer = () => (
  <OfferWrapper id={NavigationSections.OFFER}>
    <SectionName>OFERTA</SectionName>
    <SectionTitle>Oferujemy pełen zakres usług</SectionTitle>
    <SectionText>
      Jesteśmy bardzo elastyczni i umiemy dopasować rozwiązania do potrzeb
      naszych klientów. Posiadamy bardzo bogatą ofertę usług w zakresie
      instalacji elektrycznych, niskoprądowych oraz odnawialnych źródeł energii
      elektrycznej.{` `}
    </SectionText>
    <OfferElements />
  </OfferWrapper>
);

export default Offer;
