import One from '@/assets/one.svg';
import Two from '@/assets/two.svg';
import Three from '@/assets/three.svg';
import Four from '@/assets/four.svg';
import Five from '@/assets/five.svg';
import Six from '@/assets/six.svg';
import Seven from '@/assets/seven.svg';
import Eight from '@/assets/eight.svg';
import Nine from '@/assets/nine.svg';
import Ten from '@/assets/ten.svg';

interface OfferElementsList {
  id: number;
  name: string;
  svg: JSX.Element;
}

export const offerElementsList: OfferElementsList[] = [
  {
    id: 1,
    name: `Instalacje elektryczne`,
    svg: <One />,
  },
  {
    id: 2,
    name: `Instalacje alarmowe`,
    svg: <Two />,
  },
  {
    id: 3,
    name: `Sieci komputerowe`,
    svg: <Three />,
  },
  {
    id: 4,
    name: `Monitoring`,
    svg: <Four />,
  },
  {
    id: 5,
    name: `Inteligentne instalacje`,
    svg: <Five />,
  },
  {
    id: 6,
    name: `Instalacje odgromowe`,
    svg: <Six />,
  },
  {
    id: 7,
    name: `Prefabrykacja rozdzielnic`,
    svg: <Seven />,
  },
  {
    id: 8,
    name: `Fotowoltaika`,
    svg: <Eight />,
  },
  {
    id: 9,
    name: `Pomiary elektryczne`,
    svg: <Nine />,
  },
  {
    id: 10,
    name: `Projektowanie instalacji elektrycznych`,
    svg: <Ten />,
  },
];
