import styled from 'styled-components';
import { SectionText, SectionTitle } from '../../common/commonStyled';
import aboutBackgroundImage from './aboutBackgroundImage.webp';

export const AboutWrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray400};

  @media (max-width: ${({ theme }) => theme.breakpoint.navigation}) {
    display: block;
  }
`;

export const Mask = styled.div`
  background-image: url(${aboutBackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.navigation}) {
    display: none;
  }
`;

export const AboutContent = styled.div`
  padding: 100px 80px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: 40px 20px;
  }
`;

export const AboutTitle = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.white};
`;

export const AboutText = styled(SectionText)`
  color: ${({ theme }) => theme.colors.white};
`;
