import { Helmet } from 'react-helmet';
import Navigation from '@/components/Navigation/Navigation';
import Header from '@/components/Header/Header';
import About from '@/components/About';
import Layout from '@/components/Layout';
import Offer from '@/components/Offer';
import React, { Suspense } from 'react';
import favicon from '../assets/logo.ico';
import ogimage from '../assets/ogImage.png';

const Home = () => {
  const RealizationsComponent = React.lazy(
    () => import(`@/components/Realizations`),
  );
  const ReferenceComponent = React.lazy(() => import(`@/components/Reference`));
  const FeedbackComponent = React.lazy(() => import(`@/components/Feedback`));
  const ContactComponent = React.lazy(() => import(`@/components/Contact`));
  const FooterComponent = React.lazy(() => import(`@/components/Footer`));
  return (
    <Suspense>
      <Layout>
        <Helmet htmlAttributes={{ lang: `pl`, id: `top` }}>
          <title>ElektroKoncept</title>
          <meta
            name="description"
            content="Doświadczna firma świadcząca usługi kompleksowego wykonania instalacji elektrycznych w mieszkaniach, domach, budynkach mieszkalnych oraz biurowych. Specjalizujemy się w Instalacjach elektrycznych, Instalacjach alarmowych, Sieciach komputerowych, Monitoringu, Inteligentnych instalacjach, Instalacjach odgromowych, Prefabrykacji rozdzielnic, Fotowoltaice, Pomiarach elektrycznych, Projektowaniu instalacji elektycznych"
          />
          <meta property="og:title" content="Elektro Koncept" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="elektro-koncept.com" />
          <meta name="robots" content="index, follow" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <link rel="icon" href={favicon} />
          <link rel="canonical" href="https://elektro-koncept.com/" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
        </Helmet>
        <Navigation />
        <Header />
        <About />
        <Offer />
        <RealizationsComponent />
        <ReferenceComponent />
        <FeedbackComponent />
        <ContactComponent />
        <FooterComponent />
      </Layout>
    </Suspense>
  );
};

export default Home;
