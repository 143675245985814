import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    white: `#FFFFFF`,
    black: `#000000`,
    gray: `#222722`,
    gray100: `#889088`,
    gray200: `#5C635C`,
    gray400: `#141714`,
    gray500: `#030403`,
    green: `#00AD14`,
    green100: `#96E69F`,
    green200: `#56CE64`,
    green400: `#00680C`,
    green500: `#003406`,
    grayscale100: `#F2F2F2`,
    grayscale200: `#DADADA`,
    grayscale300: `#D1D1D1`,
    grayscale400: `#B3B3B3`,
    grayscale500: `#969696`,
    danger100: `#DE1414`,
    danger200: `#C51010`,
    background: `#E5E5E5`,
  },

  fontSize: {
    title: `48px`,
    mobileTitle: `31px`,
    sectionTitle: `25px`,
    medium: `20px`,
    mediumMobile: `13px`,
    standard: `16px`,
    mobileStandard: `8px`,
    small: `10px`,
  },

  lineHeight: {
    title: `1.2`,
    standard: `1.4`,
    small: `1.5`,
  },

  breakpoint: {
    realizations: `1330px`,
    navigation: `1000px`,
    mobile: `600px`,
  },
};
