import { useState, useCallback, useEffect } from 'react';

export const useMobileSizeDetector = (size: string) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const media = window.matchMedia(`(max-width:${size})`);
      media.addListener(updateTarget);

      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeListener(updateTarget);
    }
  }, []);

  return targetReached;
};
