import styled, { css } from 'styled-components';
import { LogoIcon } from '../../common/LogoIcon';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const NavigationWrapper = styled.nav<{
  isScrolled: boolean;
  isMenuMobileVisible: boolean;
}>`
  width: 100%;
  height: 82px;
  padding: 0 80px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.standard};
  line-height: ${({ theme }) => theme.lineHeight.standard};
  font-weight: 600;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  z-index: 10;

  ${({ isScrolled, isMenuMobileVisible }) =>
    (isScrolled || isMenuMobileVisible) &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    `}

  @media(max-width: ${({ theme }) => theme.breakpoint.navigation}) {
    height: 58px;
    padding: 0 20px;
  }
`;

export const Logo = styled(LogoIcon)<{
  isScrolled: boolean;
  isMenuMobileVisible: boolean;
}>`
  width: 206px;
  height: 23px;
  fill: ${({ theme }) => theme.colors.black};
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;

  ${({ isScrolled, isMenuMobileVisible }) =>
    !isScrolled &&
    !isMenuMobileVisible &&
    css`
      display: none;
    `}

  @media(max-width: ${({ theme }) => theme.breakpoint.navigation}) {
    width: 156px;
    height: 17px;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 0 30px;
  margin: 0 0 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoint.navigation}) {
    display: none;
  }
`;

export const ListItemLink = styled(AnchorLink)<{
  isScrolled: boolean;
  isLogo: boolean;
}>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  ${({ isLogo }) =>
    isLogo &&
    css`
      line-height: 0;
    `}

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      color: ${({ theme }) => theme.colors.black};

      &:hover {
        color: ${({ theme }) => theme.colors.green};
        text-decoration: none;
      }
    `}
`;

export const HamburgerMenuWrapper = styled.div`
  margin-left: auto;
`;

export const HamburgerMenu = styled.div`
  height: 18px;
  width: 24px;
  display: none;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakpoint.navigation}) {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`;

export const HamburgerMenuItem = styled.div<{
  isScrolled: boolean;
  isOpen: boolean;
}>`
  width: 100%;
  border-top: 2px solid ${({ theme }) => theme.colors.white};
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;

  ${({ isScrolled }: { isScrolled: boolean }) =>
    isScrolled &&
    css`
      border-top: 2px solid ${({ theme }) => theme.colors.black};
    `}

  ${({ isOpen }: { isOpen: boolean }) =>
    isOpen &&
    css`
      border-top: 2px solid ${({ theme }) => theme.colors.black};

      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        position: fixed;
        top: 8px;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        position: fixed;
        top: 8px;
      }
    `}
`;

export const MobileList = styled.ul`
  margin: 0;
  list-style-type: none;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  width: 100%;
  height: calc(100vh - 58px);
  position: absolute;
  top: 57px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileListItemWrapper = styled.li`
  height: 54px;
`;

export const MobileListItem = styled(ListItemLink)`
  color: ${({ theme }) => theme.colors.black};
`;
