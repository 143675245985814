import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { LogoIcon } from '../../common/LogoIcon';
import backgroundImage from './headerBackgroundImage.webp';
import backgroundImageMobile from './headerBackgroundImageMobile.webp';

export const HeaderWrapper = styled.section`
  width: 100%;
  height: 665px;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: bottom;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    background-image: url(${backgroundImageMobile});
    padding: 16px 20px 40px;
    height: 475px;
  }
`;

export const Logo = styled(LogoIcon)`
  width: 300px;
  height: 35px;
  fill: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 185px;
    height: 21px;
  }
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: 800;
  line-height: ${({ theme }) => theme.lineHeight.title};
  color: ${({ theme }) => theme.colors.white};
  margin: 51px 0 0;
  max-width: 635px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.mobileTitle};
    margin: 30px 0 0;
  }
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineHeight.title};
  color: ${({ theme }) => theme.colors.white};
  margin: 20px 0 0;
  max-width: 420px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.standard};
    line-height: ${({ theme }) => theme.lineHeight.standard};
  }
`;

export const Button = styled(AnchorLink)`
  font-size: ${({ theme }) => theme.fontSize.standard};
  font-weight: 600;
  line-height: ${({ theme }) => theme.lineHeight.title};
  color: ${({ theme }) => theme.colors.white};
  margin: 40px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.white};
  padding: 10px 24px;
  text-decoration: none;
  width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin: 30px 0 0;
  }
`;
