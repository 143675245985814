import { theme } from '@/theme';
import { useMobileSizeDetector } from '@/useMobileSizeDetector';
import { RefObject, useEffect, useRef, useState } from 'react';

export const useOutsideComponentClick = (
  ref: RefObject<HTMLDivElement>,
  callback: () => void,
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener(`mousedown`, handleClickOutside);

    return () => {
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, [ref]);
};

export const useHandleMobileMenu = () => {
  const isMobile = useMobileSizeDetector(theme.breakpoint.navigation);
  const [isMenuMobileVisible, setIsMenuMobileVisible] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setIsMenuMobileVisible(false);
    }
  }, [isMobile]);

  const closeMenu = () => setIsMenuMobileVisible(false);

  const mobileMenu = useRef<HTMLDivElement>(null);

  useOutsideComponentClick(mobileMenu, closeMenu);

  const toggleMenuVisibility = () => {
    setIsMenuMobileVisible(!isMenuMobileVisible);
  };

  return {
    isMenuMobileVisible,
    mobileMenu,
    closeMenu,
    toggleMenuVisibility,
  };
};
