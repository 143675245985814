import { SectionName } from '../../common/commonStyled';
import { NavigationSections } from '../../constants';
import {
  AboutContent,
  AboutText,
  AboutTitle,
  AboutWrapper,
  Mask,
} from './styled';

const About = () => (
  <AboutWrapper id={NavigationSections.ABOUT}>
    <AboutContent>
      <SectionName>O NAS</SectionName>
      <AboutTitle>
        Jesteśmy młodym zespołem, który świadczy usługi na wysokim poziomie
      </AboutTitle>
      <AboutText>
        Dzięki naszej wiedzy jesteśmy w stanie sprostać zadaniom jakie stawia,
        stale rozwijająca się branża budowlana w zakresie instalacji
        elektrycznych. Nasza kadra pracownicza posiada wykształcenie,
        doświadczanie oraz uprawnienia elektryczne, dzięki czemu potrafimy
        szybko i sprawnie zrealizować każde powierzone nam zadanie. Nasze
        działania są prowadzone na terenie Krakowa i okolic, ale nie boimy się
        wyzwań nawet na drugim końcu Polski! Bardzo szybko odpowiadamy na
        zapytania ofertowe.
      </AboutText>
    </AboutContent>
    <Mask />
  </AboutWrapper>
);

export default About;
