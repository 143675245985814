import styled from 'styled-components';

export const SectionName = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.standard};
  font-weight: 600;
  line-height: ${({ theme }) => theme.lineHeight.standard};
  color: ${({ theme }) => theme.colors.green};
  margin: 0;
`;

export const SectionTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.sectionTitle};
  font-weight: 800;
  line-height: ${({ theme }) => theme.lineHeight.standard};
  color: ${({ theme }) => theme.colors.gray};
  margin: 8px 0 0;
  max-width: 535px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.medium};
  }
`;

export const SectionText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.standard};
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineHeight.standard};
  color: ${({ theme }) => theme.colors.gray};
  margin: 20px 0 0;
  max-width: 625px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.mediumMobile};
    line-height: ${({ theme }) => theme.lineHeight.small};
  }
`;

export const Green = styled.span`
  color: ${({ theme }) => theme.colors.green};
`;
