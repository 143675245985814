import { RefObject } from 'react';
import navigationElements from '../../common/navigationElements';
import {
  HamburgerMenuWrapper,
  HamburgerMenu,
  HamburgerMenuItem,
  MobileListItem,
  MobileList,
  MobileListItemWrapper,
} from './styled';

export const MenuMobile = ({
  isScrolled,
  isMenuMobileVisible,
  mobileMenu,
  closeMenu,
  toggleMenuVisibility,
}: {
  isScrolled: boolean;
  isMenuMobileVisible: boolean;
  mobileMenu: RefObject<HTMLDivElement>;
  closeMenu: () => void;
  toggleMenuVisibility: () => void;
}): JSX.Element => {
  const HamburgerMenuItems = [1, 2, 3];

  return (
    <HamburgerMenuWrapper ref={mobileMenu}>
      <HamburgerMenu onClick={toggleMenuVisibility}>
        {HamburgerMenuItems.map((item) => (
          <HamburgerMenuItem
            isScrolled={isScrolled}
            key={item}
            isOpen={isMenuMobileVisible}
          />
        ))}
      </HamburgerMenu>
      {isMenuMobileVisible && (
        <MobileList>
          {navigationElements.map((navigationElement) => (
            <MobileListItemWrapper
              onClick={closeMenu}
              key={navigationElement.id}
            >
              <MobileListItem
                isScrolled={isScrolled}
                isLogo={false}
                to={navigationElement.href}
              >
                {navigationElement.title}
              </MobileListItem>
            </MobileListItemWrapper>
          ))}
        </MobileList>
      )}
    </HamburgerMenuWrapper>
  );
};
