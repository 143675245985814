import styled from 'styled-components';

export const OfferWrapper = styled.section`
  padding: 100px 80px 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    padding: 40px 20px 0;
  }
`;

export const OfferList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 60px 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(406px, 1fr));
  gap: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 20px;
  }
`;

export const OfferListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayscale400};
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  height: 60px;
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineHeight.standard};
  color: ${({ theme }) => theme.colors.gray};
  margin-left: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    height: unset;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSize.standard};
    text-align: center;
  }

  svg {
    width: 60px;
    height: 60px;
    fill: ${({ theme }) => theme.colors.green};
  }
`;
