import { NavigationSections } from '../constants';

interface NavigationElements {
  id: number;
  title: string;
  href: string;
}

const navigationElements: NavigationElements[] = [
  {
    id: 1,
    title: `O NAS`,
    href: `#${NavigationSections.ABOUT}`,
  },
  {
    id: 2,
    title: `OFERTA`,
    href: `#${NavigationSections.OFFER}`,
  },
  {
    id: 3,
    title: `REALIZACJE`,
    href: `#${NavigationSections.REALIZATIONS}`,
  },
  {
    id: 4,
    title: `REFERENCJE`,
    href: `#${NavigationSections.REFERENCE}`,
  },
  {
    id: 5,
    title: `OPINIE`,
    href: `#${NavigationSections.FEEDBACK}`,
  },
  {
    id: 6,
    title: `KONTAKT`,
    href: `#${NavigationSections.CONTACT}`,
  },
];

export default navigationElements;
