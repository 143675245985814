import { theme } from '../../theme';
import { Button, Description, HeaderWrapper, Logo, Title } from './styled';

const Header = () => (
  <HeaderWrapper>
    <Logo color={theme.colors.white} />
    <Title>Kompleksowe wykonanie instalacji elektrycznych</Title>
    <Description>
      W mieszkaniach, domach, budynkach mieszkalnych oraz biurowych.
    </Description>
    <Button to="#contact">Darmowa wycena</Button>
  </HeaderWrapper>
);

export default Header;
