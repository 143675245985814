import React, { PropsWithChildren } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from '../theme';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  
  *, ::before, ::after {
    box-sizing: inherit;
  }
  
  body{
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-color: ${({ theme }) => theme.colors.white};
  }

  * {
    scrollbar-width: auto;
    scrollbar-color:  ${({ theme }) => theme.colors.grayscale100}  ${({
  theme,
}) => theme.colors.white};
  }

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background:  ${({ theme }) => theme.colors.white};
  }

  *::-webkit-scrollbar-thumb {
    background-color:  ${({ theme }) => theme.colors.grayscale100};
    border-radius: 24px;
    border: 3px solid  ${({ theme }) => theme.colors.white};
  }
`;

export default function Layout({ children }: PropsWithChildren<any>) {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <GlobalStyle theme={theme} />
        {children}
      </ThemeProvider>
    </React.Fragment>
  );
}
