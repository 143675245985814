import { useEffect, useState } from 'react';
import { theme } from '../../theme';
import { useMobileSizeDetector } from '../../useMobileSizeDetector';
import { MenuMobile } from './MenuMobile';
import navigationElements from '../../common/navigationElements';
import { NavigationWrapper, Logo, List, ListItemLink } from './styled';
import { useHandleMobileMenu } from './useOutsideComponentClick';

const Navigation = (): JSX.Element => {
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useMobileSizeDetector(theme.breakpoint.navigation);
  const { isMenuMobileVisible, mobileMenu, closeMenu, toggleMenuVisibility } =
    useHandleMobileMenu();

  const handleOnScroll = () => {
    const position = window.pageYOffset > 0;
    setIsScrolled(position);
  };

  useEffect(() => {
    window.addEventListener(`scroll`, handleOnScroll);

    return () => {
      window.removeEventListener(`scroll`, handleOnScroll);
    };
  }, []);

  return (
    <NavigationWrapper
      isMenuMobileVisible={isMenuMobileVisible}
      isScrolled={isScrolled}
    >
      <ListItemLink isScrolled={isScrolled} isLogo={true} to="#top">
        <Logo
          color={theme.colors.green}
          isMenuMobileVisible={isMenuMobileVisible}
          isScrolled={isScrolled}
        />
      </ListItemLink>
      {isMobile ? (
        <MenuMobile
          isScrolled={isScrolled}
          isMenuMobileVisible={isMenuMobileVisible}
          mobileMenu={mobileMenu}
          closeMenu={closeMenu}
          toggleMenuVisibility={toggleMenuVisibility}
        />
      ) : (
        <List>
          {navigationElements.map((navigationElement) => (
            <li key={navigationElement.id}>
              <ListItemLink
                isScrolled={isScrolled}
                isLogo={false}
                to={navigationElement.href}
              >
                {navigationElement.title}
              </ListItemLink>
            </li>
          ))}
        </List>
      )}
    </NavigationWrapper>
  );
};

export default Navigation;
