import { offerElementsList } from './offerElementsList';
import { OfferList, OfferListItem } from './styled';

export const OfferElements = () => (
  <OfferList>
    {offerElementsList.map((offerElement) => (
      <OfferListItem key={offerElement.id}>
        {offerElement.svg}
        {offerElement.name}
      </OfferListItem>
    ))}
  </OfferList>
);
